import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Image from "../../components/image"
import QuotePrimary from "../../components/quote-primary"
import Stack from "../../components/stack"
import Text from "../../components/text"

const Kommentiert = () => {
  const data = useStaticQuery(graphql`
    query HegelKommentiertQuery {
      reichlGanzheitHegel: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-ganzheit-hegel.png" }
      ) {
        ...largeImage
      }
      reichlGanzheitHoelderlin: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-ganzheit-hoelderlin.png" }
      ) {
        ...largeImage
      }
      reichlLiebeHegel: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-liebe-hegel.png" }
      ) {
        ...largeImage
      }
      reichlFreiheitHoelderlin: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-freiheit-hoelderlin.png" }
      ) {
        ...largeImage
      }
      reichlFreiheitHegel: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-freiheit-hegel.png" }
      ) {
        ...largeImage
      }
      reichlLiebeHoelderlin: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-liebe-hoelderlin.png" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack space={[12, 24]}>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Hegel und Hölderlin kommentiert
          </Heading>
          <Paragraph>
            Das Verhältnis von Subjekt und Objekt, Einheit und Entzweiung, der
            Freiheit des Einzelnen und seiner Zugehörigkeit zu einer
            Gemeinschaft beschäftige Hölderlin und Hegel gleichermaßen. Während
            Hegel diese Themen theoretisch aufarbeitete, übersetzte sie
            Hölderlin auch ins Literarische. Ulrich Schlösser und Florian Neuner
            vom Philosophischen Seminar der Eberhard Karls Universität Tübingen
            haben zentrale Passagen aus den Werken der beiden herausgegriffen
            und kommentiert. Die Künstlerin Veronika Reichl hat dies in Form von
            Zeichnungen fortgeführt, die sich auf die Zitate beziehen.
          </Paragraph>
        </Stack>
      </Constrain>

      <Stack>
        <Heading as="h3" level={2}>
          Sein & Einheit
        </Heading>
        <Stack>
          <Image
            alt="alt"
            image={data.reichlGanzheitHegel.childImageSharp.gatsbyImageData}
          />
          <Constrain align="right">
            <QuotePrimary author="Hegel: Frühe Schriften">
              Vereinigung und Seyn sind gleichbedeutend; in jedem Saz drükt, das
              Bindewort: ist, die Vereinigung des Subjekts und Prädikats aus –
              ein Seyn, Seyn kan nur geglaubt werden
            </QuotePrimary>
          </Constrain>
        </Stack>
      </Stack>

      <Stack>
        <Image
          alt="alt"
          image={data.reichlGanzheitHoelderlin.childImageSharp.gatsbyImageData}
        />
        <Constrain align="right">
          <QuotePrimary author="Hölderlin: Vorrede  zur vorletzten Fassung zum Hyperion">
            Die seelige Einigkeit, das Seyn, im einzigen Sinne des Worts, ist
            für uns verloren und wir mussten es verlieren, wenn wir es
            erstreben, erringen sollten. Wir reißen uns los vom friedlichen Hen
            kai pan der Welt, um es herzustellen, durch uns Selbst.
          </QuotePrimary>
        </Constrain>
      </Stack>
      <Constrain>
        <Stack>
          <Paragraph>
            Ein umfassendes Sein und der unmittelbar harmonische Ausgleich
            zwischen Mensch und Welt ist zentral für Hegel und Hölderlin, unsere
            Beziehung zu ihnen aber komplex:
          </Paragraph>
          <Paragraph>
            Nur weil es den Verlust gibt, wissen wir von uns selbst und der
            Einheit. Es gibt für uns keine Möglichkeit, positiv von der Einheit
            zu wissen. Wir wissen nur negativ von ihr, nur als verlorene, denn
            sobald wir von ihr wissen, ist sie verloren. Die Verlorenheit ist
            der Modus, in welchem die Einheit im Bewusstsein vorhanden ist:
            Dieses Wissen ist ihr negativer Stellvertreter. Hegelisch
            weitergedacht bedeutet das: Glaube ist die positive Wendung der
            Negation, als welche das Sein im Bewusstsein vorhanden ist. Weil wir
            nicht direkt und nicht positiv von ihr, der Einheit, wissen können,
            ist sie Sache des Glaubens. Negativ ist sie Verlust und als solche
            ist sie als nichtvorhandene vorhanden. Positiv ist sie als Glaube in
            uns: Sie ist da als Vorhandene nicht vorhanden.
          </Paragraph>
        </Stack>
      </Constrain>

      <Stack>
        <Heading as="h3" level={2}>
          Liebe
        </Heading>
        <Stack>
          <Image
            alt="alt"
            image={data.reichlLiebeHegel.childImageSharp.gatsbyImageData}
          />
          <Constrain align="right">
            <QuotePrimary author="Hegel: Jenaer Systementwürfe">
              <span>
                Diß eigne Aufheben ist sein{" "}
                <Text as="em">Seyn für anderes</Text>; worein sein{" "}
                <Text as="em">unmittelbares Seyn</Text> umschlägt. Sein eignes
                Aufheben wird jedem am Andern als{" "}
                <Text as="em">Seyn für andres</Text>. Das Andre ist also für
                mich, d. h. es weiß sich in mir. Es ist nur Seyn für Andres d.
                h. es ist ausser sich. Diß Erkennen ist die{" "}
                <Text as="em">Liebe</Text>.
              </span>
            </QuotePrimary>
          </Constrain>
          <Constrain>
            <Paragraph>
              Das Verhältnis zwischen Einheit und Differenz bzw.
              Unterschiedensein hat Hegel und Hölderlin bei vielen Phänomenen
              interessiert. Die Liebe findet in diesem Kontext besondere
              Aufmerksamkeit: Ich bin in ihr nicht einfach so, wie ich mir
              unmittelbar gegeben bin, sondern bin auch für den Anderen da,
              mache seine Anliegen zu meinen – gerade so, wie er es in Bezug auf
              mich tut. Wenn sich beide gänzlich zugunsten des Anderen aufheben
              würden, kann das natürlich nicht klappen. Für Hegel ist die Liebe
              ein Anerkennungsverhältnis, in dem beide sich sowohl im anderen
              finden als auch diesem seine Selbstständigkeit lassen.
            </Paragraph>
          </Constrain>
        </Stack>
        <Stack>
          <Image
            alt="alt"
            image={data.reichlLiebeHoelderlin.childImageSharp.gatsbyImageData}
          />
          <Constrain align="right">
            <QuotePrimary author="Hölderlin: Hyperion">
              Wie der Zwist der Liebenden, sind die Dissonanzen der Welt.
              Versöhnung ist mitten im Streit und alles Getrennte findet sich
              wieder.
            </QuotePrimary>
          </Constrain>
          <Constrain>
            <Paragraph>
              Auch Hölderlin ist zu viel Einheit suspekt: Dissonanzen gehören
              zur Welt wie der Streit zu den Liebenden. Die Kraft liegt in der
              Fähigkeit zur Versöhnung. Die Versöhnung setzt Streit voraus. Sie
              gelingt nur, wenn man die Liebe als Versuch versteht, die
              Andersartigkeit gleichzeitig zu überwinden und zu erhalten.
            </Paragraph>
          </Constrain>
        </Stack>
      </Stack>

      <Stack>
        <Heading as="h3" level={2}>
          Freiheit
        </Heading>
        <Stack>
          <Image
            alt="alt"
            image={
              data.reichlFreiheitHoelderlin.childImageSharp.gatsbyImageData
            }
          />
          <Constrain align="right">
            <QuotePrimary author="Hölderlin: Hyperion">
              Es ist aus, Diotima! unsre Leute haben geplündert, gemordet, ohne
              Unterschied, auch unsre Brüder sind erschlagen, die Griechen in
              Misistra, die Unschuldigen, oder irren sie hülflos herum und ihre
              todte Jammermiene ruft Himmel und Erde zur Rache gegen die
              Barbaren, anderen Spitze ich war. Nun kann ich hingehn und von
              meiner guten Sache predigen. O nun fliegen alle Herzen mir zu!
              Aber ich habs auch klug gemacht. Ich habe meine Leute gekannt. In
              der That! es war ein außerordentlich Project, durch eine
              Räuberbande mein Elysium zu pflanzen.
            </QuotePrimary>
          </Constrain>
          <Constrain>
            <Stack>
              <Paragraph>
                Hölderlin gestaltet die Erfahrung des Scheiterns im
                Freiheitskampf in seinem Roman ›Hyperion‹ literarisch. Er
                verbindet damit zwei Themen und Welten, die für sein Schaffen
                leitend waren: die Begeisterung für die Französische Revolution
                und die Liebe zu Griechenland. Ihm schwebte in der Schönheit ein
                Ideal einer Einheit der Gegensätze vor, das die griechische
                Antike in seiner Vorstellung belebte.
              </Paragraph>
              <Paragraph>
                Darin soll die Dichtung, als Verwirklichung dieser Schönheit und
                damit jener Einheit, dem Handeln und der Liebe überlegen sein.
                Handeln nämlich trägt die bei Hegel angesprochene Möglichkeit
                des Scheiterns und der entfremdenden Verkehrung in sich. Mehr
                noch: Es scheint in sich selbst widersprüchlich und bringt eine
                aporienhafte Wirklichkeit zum Vorschein. Außerdem ist das
                Resultat des Handelns nur von kurzer Dauer und die Ideale der
                Revolution können für eigene Zwecke missbraucht werden, was
                Hyperions Verzweifl ung im obigen Zitat ausdrückt. Eigentlich
                wollte er sich dem griechischen Freiheitskampf anschließen.
                Dieser fand ein paar Jahre vor der französischen Revolution
                tatsächlich statt und Hölderlin parallelisiert ihn damit. Er
                muss aber einsehen, dass seine Streitgenossen nicht dieselben
                hohen Ziele im Kopf und vor allem im Herzen haben: Von der
                Gesinnung her sind sie eher einer Räuberbande vergleichbar.
              </Paragraph>
              <Paragraph>
                Hyperions Enttäuschung ist hierbei doppelt: Einerseits zeigt
                sich das wahre Gesicht der angeblichen Revolutionäre, anderseits
                erkennt er, dass das Griechenland, wie es ihm in seinem Ideal
                der Antike vorschwebt, in seiner Zeit nicht wiederzufinden ist:
                Ruinen und der Verfall der Gesinnungen treten vielmehr zu Tage.
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
        <Stack>
          <Image
            alt="alt"
            image={data.reichlFreiheitHegel.childImageSharp.gatsbyImageData}
          />
          <Constrain align="right">
            <QuotePrimary author="Hegel: Phänomenologie des Geistes">
              <Stack>
                <p>
                  Hiermit ist der Geist als absolute Freyheit vorhanden; er ist
                  das Selbstbewußtseyn, welches sich erfaßt, daß seine Gewißheit
                  seiner selbst, das Wesen aller geistigen Massen der realen so
                  wie der übersinnlichen Welt, oder umgekehrt, daß Wesen und
                  Wirklichkeit das Wissen des Bewußtseyns von sich ist. […]
                </p>
                <p>
                  In dieser absoluten Freyheit sind also alle Stände, welche die
                  geistigen Wesen sind, worein sich das Ganze gliedert, getilgt;
                  das einzelne Bewußtseyn, das einem solchen Gliede angehörte,
                  und in ihm wollte und vollbrachte, hat seine Schranke
                  aufgehoben: sein Zweck ist der allgemeine Zweck, seine Sprache
                  das allgemeine Gesetz, sein Werk das allgemeine Werk. […]
                </p>
                <p>
                  Das einzige Werk und That der allgemeinen Freyheit ist daher
                  der Tod, und zwar ein Tod, der keinen innern Umfang und
                  Erfüllung hat, denn was negirt wird, ist der unerfüllte Punkt
                  des absolutfreyen Selbsts; er ist also der kälteste, platteste
                  Tod, ohne mehr Bedeutung, als das Durchhauen eines Kohlhaupts
                  oder ein Schluck Wassers.
                </p>
              </Stack>
            </QuotePrimary>
          </Constrain>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Kommentiert
