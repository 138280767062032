/** @jsx jsx */

import React, { useState } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { jsx } from "theme-ui"
import Box from "./box"
import Modal from "./modal"
import SquareButton from "./square-button"
import Stack from "./stack"
import Text from "./text"

const TableOfContents = ({ items, label }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const renderNavItem = (item, index) => {
    return item ? (
      <Box
        as="a"
        onClick={closeModal}
        href={item.url || `#${item.anchor}`}
        key={`nav_${index}`}
        aria-label={item.title}
      >
        <Text
          as="span"
          size={[2, 2, 3, 4]}
          sans
          bold
          pr={6}
          sx={{
            ":hover, :focus": {
              color: "primary",
            },
          }}
        >
          {item.title}
        </Text>
      </Box>
    ) : null
  }

  return (
    <>
      <Box
        position="fixed"
        zIndex="40"
        left={["50%"]}
        right={["auto"]}
        bottom={0}
        px={1}
        pb={6}
        minWidth="280px"
        sx={{
          transform: ["translateX(-50%)"],
        }}
      >
        <Stack space={3} alignX="center">
          <SquareButton onClick={openModal} icon="book" aria-label={label}>
            <Box display={["none", "inline-flex"]}>{label}</Box>
          </SquareButton>
        </Stack>
      </Box>
      <Modal isOpen={modalIsOpen} onClose={closeModal} contentLabel={label}>
        <Box
          as="nav"
          height="100%"
          display="flex"
          alignX="center"
          alignY="center"
        >
          <Stack as="ul" dividers space={3}>
            {items.map((item) => renderNavItem(item))}
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

TableOfContents.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
}

TableOfContents.defaultProps = {
  label: "Inhaltsverzeichnis",
}

export default TableOfContents
