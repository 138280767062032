import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Anchor from "../../components/anchor"
import Breadcrumb from "../../components/breadcrumb"
import Caption from "../../components/caption"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Inline from "../../components/inline"
import Layout from "../../layouts/default"
import MarkerHighlight from "../../components/marker-highlight"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import SectionSpacing from "../../components/section-spacing"
import Stack from "../../components/stack"
import TableOfContents from "../../components/table-of-contents"
import Text from "../../components/text"
import Tooltip from "../../components/tooltip"

import Attribution from "../../content/hegel-hoelderlin/attribution"
import Begegnungen from "../../content/hegel-hoelderlin/begegnungen"
import FreundschaftenInBriefen from "../../content/hegel-hoelderlin/freundschaften-in-briefen"
import Kommentiert from "../../content/hegel-hoelderlin/kommentiert"
import Lektuere from "../../content/hegel-hoelderlin/lektuere"
import PhilosophiePoesie from "../../content/hegel-hoelderlin/philosophie-poesie"
import Stammbuch from "../../content/hegel-hoelderlin/stammbuch"
import TuebingerGeruechte from "../../content/hegel-hoelderlin/tuebinger-geruechte"

const HegelHoelderlinPage = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "og_images/hegel-hoelderlin.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
      }
    `
  )

  const RoomItem = (props) => {
    const { children, purpose } = props
    return (
      <Text as="div" sans size={[2, 3, 4]} color={purpose ? "text" : "whisper"}>
        {purpose ? <Tooltip content={purpose}>{children}</Tooltip> : children}
      </Text>
    )
  }

  return (
    <Layout backdrop="hegel-hoelderlin">
      <Seo
        title="Hegel, Hölderlin und ihre Tübinger Studienjahre"
        description="Friedrich Hölderlin und Georg Wilhelm Friedrich Hegel, beide 1770 geboren – der eine im März, der andere im August –, verbrachten fünf gemeinsame Studienjahre am Evangelischen Stift in Tübingen. Beide sollten sie Theologen werden. Aus dem einen wurde ein Dichter, aus dem anderen ein Philosoph. Die Sprache, ihr Einfluss auf unser Denken und wie wir mit ihr die Welt erfassen können, beschäftigte sie beide in ihren Werken und ihrem gegenseitigen Gedankenaustausch."
        image={file.childImageSharp.gatsbyImageData}
      />
      <TableOfContents
        items={[
          {
            anchor: "begegnungen",
            title: "Begegnungen mit Hegel und Hölderlin",
          },
          {
            anchor: "freundschaften-in-briefen",
            title: "Drei Freundschaften in Briefen",
          },
          {
            anchor: "tuebinger-geruechte",
            title: "Tübinger Gerüchte",
          },
          {
            anchor: "stammbuch",
            title: "Versteckte Botschaften auf Stammbuchblättern",
          },
          {
            anchor: "kommentiert",
            title: "Hegel und Hölderlin kommentiert",
          },
          {
            anchor: "philosophie-poesie",
            title: "Philosophie & Poesie",
          },
          {
            anchor: "lektuere",
            title: "Lektüreerfahrungen in Text und Bild",
          },
        ]}
      />
      <SectionSpacing>
        <Stack>
          <Stack>
            <Breadcrumb
              items={[
                {
                  title: "Digital",
                  link: "/digital",
                },
                {
                  title: "Sonderausstellungen",
                  link: "/sonderausstellungen",
                },
                {
                  title: "Idealismusschmiede in der Philosophen-WG",
                  link: "/sonderausstellungen/hegel-hoelderlin",
                },
              ]}
            />
            <PageTitle>Idealismusschmiede in der Philosophen-WG</PageTitle>
            <Heading as="h2" level={3}>
              Hegel, Hölderlin und ihre Tübinger Studienjahre
            </Heading>
          </Stack>
          <Paragraph dropcap={true}>
            Friedrich Hölderlin und Georg Wilhelm Friedrich Hegel, beide 1770
            geboren – der eine im März, der andere im August –, verbrachten fünf
            gemeinsame Studienjahre am Evangelischen Stift in Tübingen. Beide
            sollten sie Theologen werden. Aus dem einen wurde ein Dichter, aus
            dem anderen ein Philosoph. Die Sprache, ihr Einfluss auf unser
            Denken und wie wir mit ihr die Welt erfassen können, beschäftigte
            sie beide in ihren Werken und ihrem gegenseitigen Gedankenaustausch.
            Mit dem gerade einmal 15-jährigen Friedrich Wilhelm Joseph Schelling
            und sieben weiteren Stipendiaten bezogen sie 1790 eine der engen
            beheizbaren Winterstuben.
          </Paragraph>
          <Constrain>
            <Stack>
              <Inline alignX="center">
                <RoomItem>Mömpelgarder Sphäre</RoomItem>
                <RoomItem purpose="Dieses befand sich direkt vor dem Kloakenturm des Stifts. Dort waren die ungarischen Gaststudenten untergebracht.">
                  Ungarnstüblein
                </RoomItem>
                <RoomItem>Wiedertäufersphäre</RoomItem>
                <RoomItem>Hafnerstube</RoomItem>
                <RoomItem purpose="Der 2. Stock des Gebäudes, benannt nach der mit Hirschgeweihen dekorierten Jägerstube.">
                  Jägersphäre
                </RoomItem>
                <RoomItem>Erkerstube</RoomItem>
                <RoomItem>Sommermuseen</RoomItem>
                <RoomItem>Repetenten-Kabinett</RoomItem>
                <RoomItem purpose="Der u.a. als Speiselager genutzte 3. Stock, »wo selbst es immerhin am ohnordentlichsten zugehe«.">
                  Rattensphäre
                </RoomItem>
                <RoomItem>Winterstuben</RoomItem>
                <RoomItem purpose="Auf dem Dachboden wurden die Erstsemester, die spöttisch als »Ochsen« bezeichnet wurden, untergebracht">
                  Ochsenstall
                </RoomItem>
                <RoomItem>Thüringer Wäldle</RoomItem>
                <RoomItem purpose="Hier waren Hölderlin, Hegel und Schelling untergebracht. Die Augustinerstube zählte zu den wenigen beheizbaren Stuben des Stifts.">
                  <MarkerHighlight>Augustinerstube</MarkerHighlight>
                </RoomItem>
                <RoomItem>Ritterstube</RoomItem>
                <RoomItem purpose="Der mit 16 Fenstern ausgestattete Speisesaal bot Raum für geselliges Beisammensein und freien Blick auf den Neckar und die schwäbische Alb">
                  Kommunität
                </RoomItem>
                <RoomItem purpose="Diente als Lager für Zwiebeln und andere Vorräte.">
                  Zwiebelbühne
                </RoomItem>
                <RoomItem>Schneiderstube</RoomItem>
                <RoomItem>Fruchtkästen</RoomItem>
                <RoomItem purpose="Lediglich eine Pritsche und ein Tisch standen in dem Raum, in dem die Studenten bei härten Verstößen gegen die Stiftsordnung unter Arrest gestellt wurden.">
                  Karzer
                </RoomItem>
                <RoomItem>Repetentenschlafkammer</RoomItem>
                <RoomItem purpose="In den hellen und dekorativ ausgestatteten Räumen saß die Stiftsverwaltung. Auch herzogliche Visitationen und Empfänge wurden hier abgehalten.">
                  Junkersphäre
                </RoomItem>
                <RoomItem>Registratur</RoomItem>
                <RoomItem>Vogelstube</RoomItem>
                <RoomItem purpose="Diese befanden sich ungünstigerweise in unmittelbarer Nähe zum Kloakenturm. Der Gestank war zuweilen so unerträglich, dass dies nicht gerade zur Genesung beitrug.">
                  Krankenstuben
                </RoomItem>
                <RoomItem>Konvertitenstube</RoomItem>
                <RoomItem purpose="Im sogenannten »Backofen« wurden die Köche, der Torwart und andere Bedienstete bei Vergehen arrestiert.">
                  Backofen
                </RoomItem>
              </Inline>
              <Caption>
                Die Namen der Zimmer im Evangelischen Stift um 1788
              </Caption>
            </Stack>
          </Constrain>
          <Constrain>
            <Stack>
              <Paragraph>
                Von Enge, Unterdrückung und strenger Disziplinierung war der
                Studienalltag am Tübinger Stift geprägt. Im Hintergrund
                ereigneten sich währenddessen zwei gewaltige Revolutionen: Die
                Französische, mit der die gesellschaftlichen und politischen
                Ordnungen radikal infrage gestellt wurden, und die durch Kant
                hervorgerufene Revolution der Denkungsart. In den gemeinsamen
                Gesprächen machten die drei jungen Denker die Freiheit zu ihrem
                großen Thema und entwickelten, was später als die Keimzelle des
                deutschen Idealismus erkannt wurde.
              </Paragraph>
              <Paragraph>
                Die Ausstellung geht den Tübinger Anfängen der beiden
                Geistesgrößen nach, zeigt, wie sie sich gegenseitig in ihrem
                Denken beeinflusst haben und wie sie heute gelesen und
                betrachtet werden.
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
        <Anchor id="begegnungen">
          <Begegnungen />
        </Anchor>
        <Anchor id="freundschaften-in-briefen">
          <FreundschaftenInBriefen />
        </Anchor>
        <Anchor id="tuebinger-geruechte">
          <TuebingerGeruechte />
        </Anchor>
        <Anchor id="stammbuch">
          <Stammbuch />
        </Anchor>
        <Anchor id="kommentiert">
          <Kommentiert />
        </Anchor>
        <Anchor id="philosophie-poesie">
          <PhilosophiePoesie />
        </Anchor>
        <Anchor id="lektuere">
          <Lektuere />
        </Anchor>
        <Attribution />
      </SectionSpacing>
    </Layout>
  )
}

export default HegelHoelderlinPage
