import React from "react"

import Stack from "../../components/stack"
import Text from "../../components/text"
import Heading from "../../components/heading"

const Timestamp = (props) => {
  const { year, children } = props
  return (
    <Stack space={6}>
      <Heading as="p" level={4} align={[null, null, "center"]}>
        {year}
      </Heading>
      <Text as="div" lang="de" hyphens="auto" size={[2, 3, 3, 4]}>
        <Stack space={6}>{children}</Stack>
      </Text>
    </Stack>
  )
}

export default Timestamp
