import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Blockquote from "../../components/blockquote"
import Poem from "../../components/poem"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Stack from "../../components/stack"

const Stammbuch = () => {
  const data = useStaticQuery(graphql`
    query HegelStammbuchQuery {
      stammbuch1: file(
        relativePath: { eq: "hegel-hoelderlin/stammbuch-1.png" }
      ) {
        ...largeImage
      }
      stammbuch2: file(
        relativePath: { eq: "hegel-hoelderlin/stammbuch-2.png" }
      ) {
        ...largeImage
      }
      stammbuch3: file(
        relativePath: { eq: "hegel-hoelderlin/stammbuch-3.png" }
      ) {
        ...largeImage
      }
      stammbuch4: file(
        relativePath: { eq: "hegel-hoelderlin/stammbuch-4.png" }
      ) {
        ...largeImage
      }
      stammbuch5: file(
        relativePath: { eq: "hegel-hoelderlin/stammbuch-5.png" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Versteckte Botschaften auf Stammbuchblättern
          </Heading>
          <Paragraph>
            Durch Anspielungen getarnt oder hinter Zitaten versteckt zeichnet
            sich in den Stammbüchern der Stipendiaten deren Begeisterung für die
            Französische Revolution und die damit verbundene Idee der Freiheit
            und Demokratie ab. In Hegels Stammbuch ist gar von geheimen
            politischen Treffen die Rede, das Motto aus Schillers verbotenem
            Drama ›Die Räuber‹ wird zitiert. Shakespeare, Rousseau, griechische
            und lateinische Autoren spiegeln die Lektüren der Studenten.
            Zeichnungen, Spitznamen, Junge-Männer-Scherze und Erinnerungen
            zeugen zudem vom geselligen und freundschaftlichen Miteinander, das
            sich trotz der wenigen verbleibenden Zeit neben den Vorlesungen in
            Logik, Ethik, Griechisch und Hebräisch, Mathematik, Physik, Dogmatik
            und Bibelexegese entwickelte.
          </Paragraph>
        </Stack>
      </Constrain>

      <Stack space={[12, 24]}>
        <Constrain>
          <Stack>
            <Heading as="h3" level={3}>
              Alter Mann
            </Heading>
            <Image
              image={data.stammbuch1.childImageSharp.gatsbyImageData}
              alt="Stammbucheintrag in Zierschrift mit einer Karikatur die Hegel als buckligen Alten mit Gehstöcken und Mönchskutte zeigt"
            />
            <Paragraph>
              Den Spitznamen »alter Mann« hatte Hegel bereits als Student inne.
              »Gott stehe dem alten Mann bei. / Vive A ! ! !«, notiert der aus
              dem elsässischen Mömpelgard stammende Fallot am 12. Februar 1791
              in dessen Stammbuch. Daneben karikiert er den Freund als buckligen
              Alten mit Gehstöcken und Mönchskutte.
            </Paragraph>
            <Paragraph>
              Das »Vive A ! ! !« steht höchstwahrscheintlich für »Vive Auguste«,
              die Hegels zu Studienzeiten umschwärmte.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <Stack>
            <Heading as="h3" level={3}>
              Butterbrezeln im Boulanger?
            </Heading>
            <Image
              image={data.stammbuch2.childImageSharp.gatsbyImageData}
              alt="Stammbucheintrag in sauberer Handschrift und französischer Sprache"
            />
            <Paragraph>
              Auf der Rückseite erinnert Fallot an Liebesblödigkeiten und
              gesellige Beisammensein bei Wein und Butterbrezeln »chez le
              Boulanger«. Ob hier wohl das Gerücht um Hegels Tübinger
              Lieblingsweinstube herrührt?
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <Stack>
            <Heading as="h3" level={3}>
              Freiheitslieder hinter Klostermauern
            </Heading>
            <Image
              image={data.stammbuch4.childImageSharp.gatsbyImageData}
              alt="Einzeiliger Stammbucheintrag mit ausführlichem Kommentar unter dem Datum"
            />
            <Paragraph>
              »Am guten Tage sei guter Dinge: und am bösen? — je nun, da singe
              das Freiheitslied«, kommentiert der Freund Harpprecht am 25. Sept.
              1792, dem »letzten Tag [seines] Klosterlebens« sarkastisch ins
              Stammbuch.
            </Paragraph>
            <Paragraph>
              Als »Symb[ol]«, ein Erkennungszeichen, das die Stipendiaten über
              ihre Freundschaften stellten, hält Harpprecht fest:
            </Paragraph>
            <Paragraph>
              »Wenn der Winter ausgeschneiet / Tritt der schöne Sommer ein!« –
              Hoffnungsvolle Aussichten, mit denen sich die Freunde beim
              Austritt aus dem »Klosterleben« verabschieden.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <Stack>
            <Heading as="h3" level={3}>
              In tyrannos!
            </Heading>
            <Image
              image={data.stammbuch5.childImageSharp.gatsbyImageData}
              alt="Stäudlins Stammbucheintrag besteht lediglich aus zwei Zeilen und seiner Unterschrift"
            />
            <Paragraph>
              Der 12 Jahre ältere Dichter Gotthold Stäudlin, der in seinen
              Werken häufig politische Töne anschlug und dafür mit Geldstrafen
              und Arresten belegt wurde, zitiert Ulrich von Huttens »In
              tyrannos!« [Wider die Tyrannen].
            </Paragraph>
            <Paragraph>
              Als Motto von Schillers ›Räubern‹ war diese 1522 gegen den
              Pfalzgraf Ludwig vorgebrachte Wendung des politischen Publizisten
              und Reichsritters von Hutten in aller Munde. Stäudlin und
              Schiller, die zeitgleich um die dichterische Vorherschaft in
              Württemberg konkurierten und sich mit scharfen Kritiken und
              Satiren anfeindeten, hatten sich inzwischen angenähert. Stäudlin
              bemühte sich bei Schiller sogar um eine Hauslehrerstelle für
              Hegel, die letztlich Hölderlin bekam.
            </Paragraph>
          </Stack>
        </Constrain>
        <Constrain>
          <Stack>
            <Heading as="h3" level={3}>
              »Ἓν καὶ Πᾶν«
            </Heading>
            <Image
              image={data.stammbuch3.childImageSharp.gatsbyImageData}
              alt="Hölderlins Eintrag beginnt mit einem Goethe-Zitat. Unter dem Datum wurde die griechische Losung hen kai pan von anderer Hand ergänzt"
            />
            <Paragraph>
              Auch Hölderlin hat in Hegels Stammbuch ein Freundschaftsandenken
              hinterlassen. Er zitiert einen Ausspruch aus Goethes ›Iphigenie‹:
            </Paragraph>
            <Blockquote>
              <Poem>
                <span>»Lust und Liebe sind</span>
                <span>die Fittige zu großen Taten.«</span>
              </Poem>
            </Blockquote>
            <Paragraph>
              Die griechische Losung »Ἓν καὶ Πᾶν« [Eins und Alles], die Hegel
              und Hölderlin als Erinnerung über ihre Freundschaft stellten und
              zur Ausgangsfrage ihrer theoretischen und literarischen Werke
              machten, wurde allerdings von jemand anderem – vermutlich Hegel
              selbst – daruntergesetzt.
            </Paragraph>
          </Stack>
        </Constrain>
      </Stack>
    </Stack>
  )
}

export default Stammbuch
