import React from "react"

import Constrain from "../../components/constrain"
import Stack from "../../components/stack"
import Paragraph from "../../components/paragraph"
import Text from "../../components/text"
import Heading from "../../components/heading"

import Letter from "./letter"
import Timestamp from "./timestamp"
import Timeline from "./timeline"
import TimelineItem from "./timeline-item"

const FreundschaftenInBriefen = (props) => {
  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Drei Freundschaften in Briefen
          </Heading>
          <Paragraph>
            Was die drei jungen Studenten umtrieb, wie und in welchem Ton sie
            miteinander debattierten, dokumentieren die Briefe, in denen sie
            auch nach Verlassen des Stifts in Kontakt blieben. Politik und
            Gesellschaftskritik, die Philosophen der Aufklärung und die Jenaer
            Größen Fichte, Goethe und Schiller standen im Zentrum. Aber auch
            eigene Projekte, Lektüren, Begegnungen und Ansichten wurden
            ausgetauscht.
          </Paragraph>
          <Paragraph>
            Daneben kann in den Briefen auch das Zusammen- und
            Auseinanderdriften ihrer Freundschaft verfolgt werden. Während
            Schelling durch Goethes Unterstützung mit 23 Jahren eine Professur
            in Jena ergatterte und Hegel später den Weg dorthin bahnte, gelang
            es Hölderlin nicht, seinen Lebensunterhalt mit seinen literarischen
            Produktionen zu bestreiten.
          </Paragraph>
        </Stack>
      </Constrain>
      <Timeline>
        <TimelineItem align="center">
          <Timestamp year="1790">
            <p>
              <Text as="strong">Hölderlin</Text>, <Text as="strong">Hegel</Text>
              und <Text as="strong">Schelling</Text> beziehen im Herbst eine
              gemeinsame Stube.
            </p>
          </Timestamp>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="hoelderlin"
            date="16. November 1790"
            title="Hölderlin an seine Schwester"
            location="Tübingen"
          >
            Heute haben wir großen Markttag. Ich werde, statt mich von dem
            Getümmel hinüber und herüberschieben zu lassen, einen Spaziergang
            mit Hegel, der auf meiner Stube ist, auf die Wurmlinger Kapelle
            machen wo die berümte schöne Aussicht ist. Wie mirs auf meiner Stube
            gefalle? Herrlich, liebe Rike. Mein Repetent ist der beste Mann von
            der Welt. Das Zimmer ist eins der besten, liegt gegen Morgen, ist
            ser geräumig, und schon auf dem zwoten Stokwerk. Sieben von meiner
            Promotion sind drauf. Ich darf Dir nicht erst sagen, daß das
            angenemer ist, als 6 andere Unbekannte. Und die Wenigen andern sind
            auch brave Leute, darunter Breier und Schelling.
          </Letter>
        </TimelineItem>
        <TimelineItem align="center">
          <Stack>
            <Timestamp year="1793">
              <p>
                Auf Schillers Vermittlung erhält{" "}
                <Text as="strong">Hölderlin</Text> eine Hauslehrerstelle bei
                Charlotte von Kalb, die zunächst <Text as="strong">Hegel</Text>{" "}
                bekommen sollte.
              </p>
              <p>
                <Text as="strong">Hegel</Text> wird Hauslehrer in der Schweiz.
                Den Winter verbringt er in Bern, den Sommer am Bieler See. Auf
                Tells Spuren wandert er an den Ort des legendären Rüttli-Schwurs
                eine Wanderung, die <Text as="strong">Hölderlin</Text> als
                Student von Tübingen aus unternommen hatte.
              </p>
            </Timestamp>
            <Timestamp year="1794">
              <p>
                <Text as="strong">Schelling</Text> veröffentlicht seine erste
                Abhandlung ›Über die Möglichkeit einer Form der Philosophie
                überhaupt‹ und im Jahr darauf ›Vom Ich als Princip der
                Philosophie‹.
              </p>
            </Timestamp>
          </Stack>
        </TimelineItem>
        <TimelineItem align="right">
          <Letter
            variant="hoelderlin"
            date="10. Juli 1794"
            title="Hölderlin an Hegel"
            location="Waltershausen an der Saale"
          >
            <p>
              Lieber Bruder! Ich bin gewis, daß Du indessen zuweilen meiner
              gedachtest, seit wir mit der Loosung – Reich Gottes! von einander
              schieden. An dieser Loosung würden wir uns nach jeder
              Metamorphose, wie ich glaube, wiedererkennen. […]
            </p>
            <p>
              Du bist mer mit Dir selbst im Reinen, als ich. Dir ists gut,
              irgend einen Lärm in der Nähe zu haben; ich brauche die Stille. An
              Freude fehlt es mir auch nicht. Dir gebricht sie nirgends.
            </p>
            <p>
              Deine Seen und Alpen möchte ich wol zuweilen um mich haben. Die
              grose Natur veredelt, und stärkt uns doch unwiderstehlich. Dagegen
              leb’ ich im Kreise eines seltnen, nach Umfang und Tiefe, und
              Feinheit, und Gewandtheit ungewöhnlichen Geistes. Eine Frau von
              Kalb wirst Du schwerlich finden in Deinem Bern. […]
            </p>
            <p>
              Meine Beschäftigung ist jezt ziemlich konzentrirt. Kant und die
              Griechen sind beinahe meine einzige Lectüre. Mit dem ästhetischen
              Theile der kritischen Philosohie such’ ich vorzüglich vertraut zu
              werden.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="center">
          <Timestamp year="1795">
            <p>
              <Text as="strong">Hölderlin</Text> macht in Jena Bekanntschaft mit
              Schiller und Goethe und hört die Vorlesungen von Fichte.
            </p>
            <p>
              <Text as="strong">Hegel</Text> erwägt, die ungeliebte
              Hauslehrerstelle aufzugeben und als Repetent nach Tübingen
              zurückzukehren, was ihm <Text as="strong">Hölderlin</Text>{" "}
              ausredet.
            </p>
          </Timestamp>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="schelling"
            date="6. Januar 1795"
            title="Schelling an Hegel"
            location="Tübingen"
          >
            <p>
              Überhaupt scheinen unsre alte Bekannte uns nimmer zu kennen. […]
              Hölderlin – ich vergeb es seiner Laune, daß er unsrer noch nie
              gedacht hat. Hier meine Hand, alter Freund! Wir wollen uns nimmer
              fremd werden! Ich glaube sogar, wir könnten uns indeß neu geworden
              sein: desto besser zum neuen Anfang!
            </p>
            <p>
              Willst Du wissen, wie es bei uns steht? Lieber Gott […] Wir
              erwarteten alles von der Philosophie und glaubten, daß der Stoß,
              den sie auch den Tübinger Geistern beigebracht hatte, nicht so
              bald wieder ermatten würde. Es ist leider so! Der philosophische
              Geist hat hier bereits seinen Meridian erreicht…
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="right">
          <Letter
            variant="hegel"
            date="Januar 1795"
            title="Hegel an Schelling"
            location="Bern"
          >
            <p>
              Was Du mir von dem theologisch-Kantischen […] Gang der Philosophie
              in Tübingen sagst, ist nicht zu verwundern. […] Aber ich glaube,
              es wäre interessant, die Theologen, die kritisches Bauzeug zur
              Befestigung ihres gotischen Tempels herbeischaffen, in ihrem
              Ameisen-Eifer so viel [als] möglich zu stören, ihnen alles [zu]
              erschweren. […]
            </p>
            <p>
              Hölderlin schreibt mir zuweilen aus Jena, ich werde ihm wegen
              Deiner Vorwürfe machen; er hört Fichte’n und spricht mit
              Begeisterung von ihm als einem Titanen, der für die Menschheit
              kämpfe und dessen Wirkungskrais gewis nicht innerhalb der Wände
              des Auditoriums bleiben werde. Daraus, daß er Dir nicht schreibt,
              darfst Du nicht auf Kälte in der Freundschaft schließen, denn
              diese hat bei ihm gewis nicht abgenommen, und sein Interesse für
              weltbürgerliche Ideen nimmt, wie mirs scheint, immer zu.
            </p>
            <p>
              Das Reich Gottes komme und unsere Hände seyen nicht müßig im
              Schoose! […] Vernunft und Freiheit bleiben unsre Losung, und unser
              Vereinigungspunkt die unsichtbare Kirche.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="hoelderlin"
            date="26. Januar 1795"
            title="Hölderlin an Hegel"
            location="Jena"
          >
            <p>
              Göthen hab’ ich gesprochen, Bruder! Es ist der schönste Genuß
              unsers Lebens, so viel Menschlichkeit zu finden bei so viel Größe.
              Er unterhielt mich so sanft und freundlich, daß mir recht
              eigentlich das Herz lachte, u. noch lacht, wenn ich daran denke.
              Herder war auch herzlich, ergriff die Hand, zeigte aber schon mer
              den Weltman; sprach oft ganz so allegorisch, wie auch Du ihn
              kennst; […]
            </p>
            <p>
              Fichtens spekulative Blätter – Grundlage der gesammten
              Wissenschaftslehre – auch seine gedrukten Vorlesungen über die
              Bestimmung des Gelehrten werden Dich ser interessiren. Anfangs
              hatt’ ich ihn ser im Verdacht des Dogmatismus; er scheint, […]
              auch wirklich auf dem Scheidewege gestanden zu seyn, oder noch zu
              stehn – er möchte über das Factum des Bewußtseins in der Theorie
              hinaus, das ist eben so gewis, und noch auffallender transcendent,
              als wenn die bisherigen Metaphysiker über das Daseyn der Welt
              hinaus wollten – sein absolutes Ich (= Spinozas Substanz) enthält
              alle Realität; es ist alles, u. außer ihm ist nichts; es giebt
              also für dieses abs[solute] Ich kein Object, denn sonst wäre nicht
              alle Realität in ihm; ein Bewußtsein ohne Object ist aber nicht
              denkbar, und wenn ich selbst dieses Object bin, so bin ich als
              solches notwendig beschränkt, sollte es auch nur in der Zeit seyn,
              also nicht absolut; also ist in dem absoluten Ich kein Bewußtsein
              denkbar, als absolutes Ich hab ich kein Bewußtsein, und insofern
              ich kein Bewußtsein habe, insofern bin ich (für mich) nichts, also
              das absolute Ich ist (für mich) Nichts.
            </p>
            <p>
              So schrieb ich noch in Waltershausen, als ich seine ersten Blätter
              las, unmittelbar nach der Lectüre des Spinoza, meine Gedanken
              nieder; Fichte bestätiget mir…
            </p>
            <p>
              [Die nächsten Zeilen fehlen, da der untere Teil des Blattes
              abgerissen ist]
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="right">
          <Letter
            variant="hegel"
            date="30. August 1795"
            title="Hegel an Schelling"
            location="Tschugg bei Erlach"
          >
            <p>
              Dein […] Versuch, Fichtes Grundlage zu studieren […] [hat] mich in
              den Stand gesetzt, in Deinen Geist einzudringen und seinem Gange
              zu folgen, viel mehr, als ich es noch bei Deiner ersten Schrift im
              Stande war, die mir aber jetzt durch Deine zweite erklärt wird.
              […]
            </p>
            <p>
              Was mir dunkel und unentwickelt vorschwebte, hat mir Deine Schrift
              aufs Herrlichste und Befriedigendste aufgeklärt. […] Was dir im
              Wege stehen wird, verstanden zu werden […] wird, stelle ich mir
              vor, überhaupt das sein, daß die Leute schlechterdings ihr
              Nicht-Ich nicht werden aufgeben wollen. […] Ueber die Folgen, die
              das Mißverstehen Deiner Grundsätze für Dich haben könnte, bist Du
              erhaben. Du hast schweigend Dein Werk in die unendliche Zeit
              geworfen. […]
            </p>
            <p>
              Hölderlin höre ich, sei in Tübingen gewesen; gewiß habt Ihr
              angenehme Stunden mit einander zugebracht; wie sehr wünschte ich,
              der dritte Mann dazu gewesen zu seyn! […]
            </p>
            <p>
              Da Du Tübingen bald verläßt, so sei so gut mich von dem, was Du
              vorzunehmen im Sinne hast und von dem künftigen Orte Deines
              Aufenthalts, wie von allen Deinen Schicksalen, bald zu
              benachrichtigen.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="hoelderlin"
            date="25. November 1795"
            title="Hölderlin an Hegel"
            location="Stuttgart"
          >
            <p>
              Du fragst mich wegen der Repetentenstelle? Du willst Dich durch
              meinen Entschluß bestimmen lassen? Lieber! da thuest Du Dir
              Unrecht. Ich habe vorerst die Prätension gar nicht zu machen,
              tauge schlechterdings nicht dazu, und dann hab’ ich laider! noch
              ganz besondere Gründe, die ich meinen ehemaligen Tübingen
              Thorheiten danke. Aber für Dich wär’ es wohl Pflicht, insofern Du
              den Todtenerweker in Tübingen machen könntest; freilich würden die
              Todtengräber in Tübingen ihr Möglichstes gegen Dich thun.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="center">
          <Timestamp year="1796">
            <p>
              <Text as="strong">Hölderlin</Text> wird Hauslehrer in einer
              Frankfurter Bankiersfamilie, verliebt sich in die Ehefrau seines
              Arbeitgebers und macht sie zur Romanfigur. Ein Jahr später
              veröffentlicht er den 1. Band seines Briefromans ›Hyperion‹.
            </p>
          </Timestamp>
        </TimelineItem>
        <TimelineItem align="right">
          <Letter
            variant="hoelderlin"
            date="24. Februar 1796"
            title="Hölderlin an Niethammer"
            location="Frankfurt am Main"
          >
            <p>
              Die neuen Verhältnisse, in denen ich jezt lebe, sind die denkbar
              besten. Ich habe viel Muße zu eigener Arbeit, und die Philosophie
              ist wieder einmal fast meine einzige Beschäftigung. Ich habe mir
              Kant und Reinhold vorgenommen und hoffe, in diesem Element meinen
              Geist wieder zu sammeln und zu kräftigen, der durch fruchtlose
              Bemühungen zerstreut und geschwächt wurde. […]
            </p>
            <p>
              Die Philosophie ist eine Tyrannin, und ich dulde ihren Zwang mehr,
              als daß ich mich ihm freiwillig unterwerfe. In den philosophischen
              Briefen will ich das Prinzip finden, das mir die Trennungen, in
              denen wir denken und existiren, erklärt, das aber auch vermögend
              ist, den Widerstreit verschwinden zu machen, den Widerstreit
              zwischen dem Subject und dem Object, zwischen unserem Selbst und
              der Welt, ja auch zwischen Vernunft und Offenbarung, –
              theoretisch, in intellectualer Anschauung, ohne daß unsere
              praktische Vernunft zu Hilfe kommen müßte. […]
            </p>
            <p>
              Schelling, den ich vor meiner Abreise sah, ist froh, in Deinem
              Journal mitzuarbeiten und durch Dich in die gelehrte Welt
              eingeführt zu werden. Wir sprachen nicht immer accordirend
              miteinander, aber wir waren uns einig, daß neue Ideen am
              deutlichsten in der Briefform dargestellt werden können. Er ist
              mit seinen neuen Überzeugungen, wie Du wissen wirst, einen bessern
              Weg gegangen, ehe er auf dem schlechteren ans Ziel gekommen war.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="hegel"
            date="November 1796"
            title="Hegel an Hölderlin"
            location="Tschugg bei Erlach"
          >
            <p>
              …ich kann Dir nicht sagen, wie viel Freude [dein Brief] mir
              gemacht hat, und noch mehr die Hoffnung, Dich bald selbst zu sehen
              und zu umarmen. Ohne länger bei dieser angenehmen Vorstellung zu
              verweilen, laß mich gerade von der Hauptsache sprechen. Dein
              Wunsch allein, bürgt mir dafür, daß dieses Verhältniß nicht
              anders, als vorteilhaft für mich seyn kann; ich folge also ohne
              Bedenken Deinem Rufe, und entsage andern Aussichten, die sich mir
              darboten. […]
            </p>
            <p>
              Wie viel Antheil an meiner geschwinden Entschließung die Sehnsucht
              nach Dir habe, wie mir das Bild unsers Wiedersehens, der frohen
              Zukunft, mit Dir zu seyn, diese Zwischenzeit vor Augen schweben
              wird – davon nichts. Lebe wohl! Dein Hegel.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="right">
          <Letter
            variant="hoelderlin"
            date="20. November 1796"
            title="Hölderlin an Hegel"
            location="Frankfurt am Main"
          >
            <p>
              Daß Du erst in der Mitte des Jenners kommst, erträgt HE. Gogel
              geduldiger, als ich; ich wollte, wir hätten heute Neujahrsabend.
              HE. Gogel hat Deinen Brief gelesen, und war, wie ich wohl denken
              konnte, sehr vergnügt darüber. Wenn Du noch der Alte bist, so
              wirst Du in seinem Karakter und seiner Art, sich zu äußern, sehr
              viel Beziehung mit Deiner Eigentümlichkeit finden. […]
            </p>
            <p>
              Wir wollen brüderlich Müh’ und Freude theilen, alter
              Herzensfreund! Es ist recht gut, daß mich die Höllengeister, die
              ich aus Franken mitnahm, und die Luftgeister mit den
              metaphysischen Flügeln, die mich aus Jena geleiteten, seitdem ich
              in Frankfurt bin, verlassen haben. So bin ich Dir noch etwas
              brauchbar. […]
            </p>
            <p>
              Ich habe vorgestern von Dir geträumt, Du machtest noch allerlei
              weitläufige Reisen in der Schweiz herum, und ich wollte mich
              todtärgern. Nachher hatt’ ich herzliche Freude an dem Traume.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="center">
          <Timestamp year="1798">
            <p>
              <Text as="strong">Schelling</Text> erhält durch Goethes
              Unterstützung eine Professur in Jena.
            </p>
            <p>
              <Text as="strong">Hölderlin</Text> verschafft{" "}
              <Text as="strong">Hegel</Text> eine Hauslehrerstelle in Frankfurt.
              Mit dem gemeinsamen Freund Isaak von Sinclair diskutieren sie über
              Philosophie und Politik und unternehmen Wanderungen in der
              Umgebung.
            </p>
          </Timestamp>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="hoelderlin"
            date="Juli 1799"
            title="Hölderlin an Schelling"
            location="Bad Homburg"
          >
            <p>
              Mein Theurer! <br />
              Ich habe indeß zu treu und zu ernst an Deiner Sache und an Deinem
              Ruhme Theil genommen, als daß ich es mir nicht gönnen sollte, Dich
              einmal wieder an mein Dasey zu mahnen. […]
            </p>
            <p>
              Ich habe die Einsamkeit, in der ich hier seit vorigem Jahre lebe,
              dahin verwandt, um unzerstreut und mit gesammelten, unabhängigen
              Kräften vielleicht etwas Reiferes, als bisher geschehen ist, zu
              Stande zu bringen, und wenn ich schon gröstentheils der Poesie
              gelebt habe, so ließ mich doch Nothwendigkeit und Neigung mich
              nicht so weit von der Wissenschaft entfernen…
            </p>
            <p>
              Diese Materialien zusammen veranlaßten mich zu dem Entwurf eines
              humanistischen Journals, das in seinem gewöhnlichen Karakter
              ausübend poetisch, dann auch historisch und philosophisch
              belehrend wäre über Poesie, endlich im Allgemeinen historisch und
              philosophisch belehrend aus dem Gesichtspuncte der Humanität.[…]
            </p>
            <p>
              In jedem Falle, Freund meiner Jugend! wirst Du mir verzeihen, daß
              ich mich mit dem alten Zutrauen an Dich gewandt und den Wunsch
              geäußert habe, Du möchtest durch Deine Theilnahme und Gesellschaft
              in dieser Sache meinen Muth mir erhalten, der durch meine Lage und
              andere Umstände indessen vielfältige Stöße erlitten hat, wie ich
              Dir wohl gestehen darf.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="center">
          <Stack>
            <Timestamp year="1801">
              <p>
                <Text as="strong">Hegel</Text> veröffentlicht seine Schrift
                ›Differenz des Fichteschen und Schellingschen Systems‹ und
                habilitiert sich.
              </p>
              <p>
                <Text as="strong">Hölderlin</Text> beginnt eine Hauslehrerstelle
                in der Schweiz – und gibt sie nach 3 Monaten wieder auf. Zu Fuß
                macht er sich auf den Weg nach Bordeaux – und kehrt ein halbes
                Jahr später erneut zurück in die Heimat.
              </p>
            </Timestamp>
            <Timestamp year="1802">
              <p>
                <Text as="strong">Hegel</Text> und{" "}
                <Text as="strong">Schelling</Text> gründen das ›Kritische
                Journal der Philosophie‹ und halten eine gemeinsame, dialogisch
                angelegte Lehrveranstaltung in Jena.
              </p>
            </Timestamp>
            <Timestamp year="1803">
              <p>
                <Text as="strong">Schelling</Text> wechselt an die Universität
                Würzburg und heiratet Caroline Schlegel.
              </p>
            </Timestamp>
          </Stack>
        </TimelineItem>
        <TimelineItem align="right">
          <Letter
            variant="schelling"
            date="11. Juli 1803"
            title="Schelling an Hegel"
            location="Bad Cannstatt"
          >
            Der traurigste Anblick, den ich während meines hiesigen Aufenthalts
            gehabt habe, war der von Hölderlin. Seit einer Reise nach Frankreich
            […] ist er am Geist ganz zerrüttet, und obgleich noch einiger
            Arbeiten, z.B. des Übersetzens aus dem Griechischen bis zu einem
            gewissen Puncte fähig, doch übrigens in einer vollkommenen
            Geistesabwesenheit. Sein Anblick war für mich erschütternd: er
            vernachlässigt sein Äußeres bis zum Ekelhaften und hat, da seine
            Reden weniger auf Verrückung hindeuten, ganz die äußeren Manieren
            solcher, die in diesem Zustande sind, angenommen. – Hier zu Lande
            ist keine Hoffnung ihn herzustellen. Ich dachte Dich zu fragen, ob
            Du dich seiner annehmen wolltest, wenn er etwa nach Jena käme, wozu
            er Lust hatte.
          </Letter>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="hegel"
            date="16. August 1803"
            title="Hegel an Schelling"
            location="Jena"
          >
            <p>
              Vor allem laß mich Dir zu Deiner Verheiratung Glück wünschen;
              billig sollte ich Dir wenigstens ein Sonett darüber schicken,
              allein Du bist einmal gewohnt mit meiner Prosa überhaupt vorlieb
              zu nehmen, und diese erlaubt nicht, in solchen Dingen weitläufiger
              zu sein, als ein Händedruck und eine Umarmung ist. […]
            </p>
            <p>
              Ich danke Dir für mancherley Erinnerungen an Schwaben […] Noch
              unerwarteter die Erscheinung Hölderlins in Schwaben, und zwar in
              welcher Gestalt! Du hast freylich recht, daß er dort nicht wird
              genesen können; aber sonst ist er überhaupt über die Periode
              hinaus, in welcher Jena eine positive Wirkung auf einen Menschen
              haben kann; und es ist izt die Frage, ob für seinen Zustand die
              Ruhe hinreichend ist, um aus sich selbst genesen zu können. Ich
              hoffe, daß er noch immer ein gewisses Zutrauen in mich setzt, das
              er sonst zu mir hatte, und vielleicht ist dieses fähig, etwas bey
              ihm zu vermögen, wenn er hieher kommt.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="right">
          <Letter
            variant="schelling"
            date="14. Juli 1804"
            title="Schelling an Hegel"
            location="Würzburg"
          >
            Vor ohngefähr 4 Wochen überraschte mich Sinclair, es kam mir vor,
            daß mit den schnell zusammengerafften noch Fichtischen Ideen er sich
            dann übrigens so ziemlich in die Plattheit begeben hat. Er war auf
            dem Wege nach Schwaben, Hölderlin dort abzuholen, mit dem er dann
            auch hieher zurückkam. Dieser ist in einem bessern Zustand als im
            vorigen Jahr, doch noch immer in merklicher Zerrüttung. Seinen
            verkommenen geistigen Zustand drückt die Übersetzung des Sophocles
            ganz aus. Er sagte mir, daß er Bibliothekar des Landgrafen zu
            Homburg geworden sei, und ging mit Sinclair dahin.
          </Letter>
        </TimelineItem>
        <TimelineItem align="center">
          <Stack>
            <Timestamp year="1805">
              <p>
                Vermittelt durch <Text as="strong">Schelling</Text> erhält{" "}
                <Text as="strong">Hegel</Text> eine außerordentliche Professur
                in Jena.
              </p>
            </Timestamp>
            <Timestamp year="1806">
              <p>
                <Text as="strong">Hölderlin</Text> wird ins Tübinger Klinikum
                eingewiesen.
              </p>
            </Timestamp>
            <Timestamp year="1807">
              <p>
                <Text as="strong">Hegel</Text> wendet sich mit seiner
                ›Phänomenologie des Geistes‹ von Schelling ab und wird
                Chefredakteur der ›Bamberger Zeitung‹.
              </p>
              <p>
                <Text as="strong">Hölderlin</Text> wird aus dem Klinikum
                entlassen und in der Familie Zimmer aufgenommen.
              </p>
            </Timestamp>
            <Timestamp year="1811">
              <p>
                <Text as="strong">Hegel</Text> heiratet in die Nürnberger
                Brauereifamilie Tucher ein und veröffentlicht im Folgejahr seine
                ›Wissenschaft der Logik‹.
              </p>
            </Timestamp>
            <Timestamp year="1818">
              <p>
                <Text as="strong">Hegel</Text> tritt in Berlin die Nachfolge von
                Fichte an.
              </p>
            </Timestamp>
            <Timestamp year="1829">
              <p>
                <Text as="strong">Hegel</Text> wird Rektor der Universität
                Berlin. Ein Versöhnungsversuch mit{" "}
                <Text as="strong">Schelling</Text> scheitert.
              </p>
            </Timestamp>
            <Timestamp year="1831">
              <p>
                <Text as="strong">Hegel</Text> stirbt in Berlin und wird neben
                Fichte beigesetzt.
              </p>
            </Timestamp>
            <Timestamp year="1841">
              <p>
                <Text as="strong">Schelling</Text> wird vom preußischen König an
                die Universität Berlin berufen, um der »Drachensaat« des{" "}
                <Text as="strong">Hegelschen</Text> Denkens ein Ende zu
                bereiten.
              </p>
            </Timestamp>
            <Timestamp year="1843">
              <p>
                <Text as="strong">Hölderlin</Text> stirbt in Tübingen.
              </p>
            </Timestamp>
          </Stack>
        </TimelineItem>
        <TimelineItem>
          <Letter
            variant="schelling"
            date="11. Februar 1847"
            title="Schelling an Gustav Schwab"
            location="Berlin"
          >
            <p>
              Euer Hochwohlgeborn, haben mich durch Übersendung der neuen
              Ausgabe von Hölderlins Werken höchlich verbunden; […] wiewohl es
              ein wehmüthiges Vergnüngen war, mich in der Persönlichkeit des
              längst (noch vor seinem Tode) entschwundnen Freundes
              zurückzudenken.
            </p>
            <p>
              Meine Erinnerung hat zu dem einen Endpunkt meinen Eintritt in die
              Nürtinger Schule, wo Hölderlin gegen die andern, den so viel
              jüngeren zu mißhandeln geneigten Schüler, mein Schutz wurde, zum
              andern Hölderlins Erscheinung in Kl. Murrhard, wohin er im
              Frühling 1803 ohne Begleitung, zu Fuß, queerfeldein wie durch
              Instinct geführt, gelangt war, um mich zu sehen. Es war ein
              trauriges Wiedersehn, denn ich überzeugte mich bald daß dieses
              zart besaitete Instrument auf immer zerstört sey. […]
            </p>
            <p>
              Während 36 Stunden, die er bei uns im Ganzen verweilte, hat er
              nichts unschickliches, nichts seinem früheren, edlen und
              anstandsvollen Wesen widersprechendes weder gethan noch geredet.
              Es war ein schmerzlicher Abschied auf der Landstraße. Seitdem habe
              ich ihn nicht wieder gesehen.
            </p>
          </Letter>
        </TimelineItem>
        <TimelineItem align="center">
          <Timestamp year="1854">
            <p>
              <Text as="strong">Schelling</Text> stirbt in der Schweiz.
            </p>
          </Timestamp>
        </TimelineItem>
      </Timeline>
    </Stack>
  )
}

export default FreundschaftenInBriefen
