import React, { useState } from "react"
import PropTypes from "prop-types"
import { alpha } from "@theme-ui/color"

import Breakout from "../../components/breakout"
import Button from "../../components/button"
import Container from "../../components/container"
import Stack from "../../components/stack"
import Box from "../../components/box"

const Timeline = (props) => {
  const { previewSize, children } = props

  const [state, setState] = useState({
    expanded: false,
  })

  const toggleReadMore = () => {
    setState({
      ...state,
      expanded: !state.expanded,
    })
  }

  return (
    <Breakout>
      <Box px={[6, 6, 12]}>
        <Container>
          <Box
            position="relative"
            overflow="hidden"
            height={state.expanded ? "auto" : previewSize}
          >
            <Stack as="ol">
              {React.Children.toArray(children).map((child) => {
                return child
              })}
            </Stack>
            {!state.expanded ? (
              <Box
                height="12"
                position="absolute"
                left="0"
                right="0"
                bottom="0"
                width="full"
                zIndex="20"
                sx={{
                  backgroundImage: (theme) => `
                    linear-gradient(
                      180deg,
                      ${alpha("background", 0)(theme)} 0,
                      ${alpha("background", 1)(theme)} 100%
                    )
                  `,
                }}
              />
            ) : null}
          </Box>
          {!state.expanded ? (
            <Box p={6} textAlign="center">
              <Button onClick={toggleReadMore}>Alle Briefe lesen</Button>
            </Box>
          ) : null}
        </Container>
      </Box>
    </Breakout>
  )
}

Timeline.propTypes = {
  previewSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Timeline.defaultProps = {
  previewSize: "3000px",
}

export default Timeline
