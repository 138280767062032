import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Image from "../../components/image"
import QuotePrimary from "../../components/quote-primary"
import Stack from "../../components/stack"
import Text from "../../components/text"

const PhilosophiePoesie = (props) => {
  const data = useStaticQuery(graphql`
    query HegelPhilosophiePoesieQuery {
      rhein: file(
        relativePath: { eq: "hegel-hoelderlin/hoelderlin-der-rhein.png" }
      ) {
        ...largeImage
      }
      eleusis: file(
        relativePath: { eq: "hegel-hoelderlin/hegel-eleusis.png" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Philosophie & Poesie
          </Heading>
          <Stack>
            <Paragraph>
              Nicht nur Hölderlin verbindet Poesie und Theorie in seinem
              Schaffen. Auch Hegel hat sich in der Sprache der Poesie versucht.
              Sein Gedicht ›Eleusis‹, entstanden im August 1796 am Bieler See in
              der Schweiz, hat er Hölderlin gewidmet, der in seiner
              ›Rhein‹-Hymne auf den einstigen Musenplatz seines Freundes
              zurückverweist.
            </Paragraph>
            <Paragraph>
              Poesie und Philosophie gehörten für die beiden unbedingt zusammen.
              Das geht aus dem sogenannten ›Ältesten Systemprogramm des
              Deutschen Idealismus‹ deutlich hervor – ein Manuskript, an dem
              neben Hegel vermutlich auch Hölderlin und Schelling Anteil hatten:
              »Ich bin nun überzeugt«, heißt es darin, »daß der höchste Akt der
              Vernunft […] ein ästhetischer Akt ist und daß 
              <Text as="em">Wahrheit</Text> und <Text as="em">Güte</Text> nur in
              der <Text as="em">Schönheit</Text> verschwistert sind. Der
              Philosoph muß ebensoviel ästhetische Kraft besitzen als der
              Dichter. Die Poesie […] wird am Ende wieder, was sie am Anfang war
              – 
              <Text as="em">Lehrerin der Menschheit</Text>«.
            </Paragraph>
          </Stack>
        </Stack>
      </Constrain>

      <Grid columns={[1, 2]} alignY="center">
        <Image
          alt="Die zweite Seite aus Hegels Manuskript zu ›Eleusis‹ beginnt mit den Worten Mein Aug erhebt sich zu des ew’gen Himmels Wölbung, zu dir"
          caption="Hegels Gedichtmanuskript ›Eleusis‹ aus der Bibliothek der Eberhard-Karls-Universität Tübingen."
          image={data.eleusis.childImageSharp.gatsbyImageData}
        />
        <QuotePrimary author="Friedrich Wilhelm Georg Hegel: Eleusis. An Hölderlin. August 1796">
          <span>Mein Aug erhebt sich zu des ew’gen Himmels Wölbung,</span>
          <span>zu dir, o glänzendes Gestirn der Nacht!</span>
          <span>und aller Wünsche, aller Hofnungen</span>
          <span>Vergessen strömt aus deiner Ewigkeit herab;</span>
        </QuotePrimary>
      </Grid>

      <Grid columns={[1, 2]} reverse={[false, true]} alignY="center">
        <Image
          alt="Das Manuskript zeigt zwei lange Strophen der Rhein-Hymne in Hölderlins zarter Handschrift"
          caption="Hölderlins Gedichtmanuskript ›Der Rhein.‹ aus dem Stadtarchiv Bad Homburg, Depositum der Württembergischen Landesbibliothek Stuttgart"
          image={data.rhein.childImageSharp.gatsbyImageData}
        />
        <QuotePrimary author="Friedrich Hölderlin: Der Rhein. An Isaack von Sinclair 1800">
          <span>Am Bielersee in frischer Grüne zu sein,</span>
          <span>Und sorglos arm an Tönen,</span>
          <span>Anfängern gleich, bei Nachtigallen zu lernen</span>
        </QuotePrimary>
      </Grid>
    </Stack>
  )
}

export default PhilosophiePoesie
