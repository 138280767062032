import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"

import { useStaticQuery, graphql } from "gatsby"
import Stack from "../../components/stack"
import Constrain from "../../components/constrain"
import Text from "../../components/text"

import Columns from "../../components/columns"
import Column from "../../components/column"
import Container from "../../components/container"
import Image from "../../components/image"
import Button from "../../components/button"
import Box from "../../components/box"
import Heading from "../../components/heading"

const Attribution = (props) => {
  const data = useStaticQuery(graphql`
    query AttributionHegelHoelderlin {
      katalogCover: file(
        relativePath: { eq: "hegel-hoelderlin/katalog-reichl.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Constrain>
        <Stack>
          <Text size={3}>
            Die Ausstellung entstand in Kooperation mit dem Philosophischen
            Seminar der Eberhard Karls Universität Tübingen (Prof. Dr. Ulrich
            Schlösser). Sie knüpft an die vorausgegangenen
            Hegel-WG-Ausstellungen im Literaturmuseum der Moderne des Deutschen
            Literaturarchiv Marbach und im Hegel-Haus Stuttgart an und führt
            diese in Tübingen, am Ort des Geschehens, fort.
          </Text>
          <Text size={3}>
            Ausstellung: Dr. Sandra Potsch (Museum Hölderlinturm Tübingen) mit
            Prof. Dr. Ulrich Schlösser und Florian Neuner (Eberhard Karls
            Universität Tübingen); Gestaltung: Hannes Häfner; Zeichnungen:
            Veronika Reichl; Philosophie-Roboter: Fabian Neidhardt und Max
            Stieler; Manuskripte und Objekte: Deutsches Literaturarchiv Marbach,
            Hölderlin-Archiv Stuttgart und Bibliothek der Eberhard Karls
            Universität Tübingen.
          </Text>
          <Text size={3}>
            Dank an Prof. Dr. Johann Kreuzer, Beate Martin (Evangelisches Stift
            Tübingen) und Dr. Axel Kuhn für ihre Mithilfe und Anregungen sowie
            PD Dr. Stefan Gerlach, Prof. Dra. Miriam Madureira, Anita Watzel,
            Anna-Lisa Sander, Ana Munte, Emma Kroll-Reisser, Florian Neuner und
            Federica Gonzalez Luna Ortiz für ihre Beiträge zur Ausstellung.
          </Text>
        </Stack>
      </Constrain>
      <Box bg="muted" p={[6, 8, 12]}>
        <Columns
          alignY="center"
          space={[6, 8, 12]}
          collapse={[true, false]}
          reverse={[false, true]}
        >
          <Column width="content">
            <Box width={["24", "40"]}>
              <Image
                image={data.katalogCover.childImageSharp.gatsbyImageData}
                alt="Titelbild des Ausstellungkatalogs"
              />
            </Box>
          </Column>
          <Column>
            <Stack space={6}>
              <Stack space={6}>
                <Heading as="h2" level={5}>
                  Das Magazin zur Ausstellung
                </Heading>
                <Container maxWidth="lg" center={false}>
                  <Text size={[2, 3]} sans>
                    <Text as="em">Im Schaum dieser Sprache</Text>
                    <br />
                    Hegel lesen <br />
                    Texte und Zeichnungen von Veronika Reichl <br />
                    Herausgegeben und mit einem Vorwort von Sandra Potsch
                  </Text>
                </Container>
              </Stack>
              <Stack space={1}>
                <Text size={1} sans>
                  ISBN 978-3-941818-43-9
                </Text>
                <Text size={1} sans>
                  104 Seiten
                </Text>
              </Stack>
              <Button secondary href="https://www.tuebingen.de/1520.html#/1952">
                Jetzt bestellen
              </Button>
            </Stack>
          </Column>
        </Columns>
      </Box>
    </Stack>
  )
}

export default Attribution
