import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../components/audio-player"
import Box from "../../components/box"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Image from "../../components/image"
import QuoteBox from "../../components/quote-box"
import Stack from "../../components/stack"
import Text from "../../components/text"

const Lektuere = () => {
  const data = useStaticQuery(graphql`
    query HegelLektuereQuery {
      reichl1: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-1-dana.png" }
      ) {
        ...largeImage
      }
      reichl2: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-2-dana.png" }
      ) {
        ...largeImage
      }
      reichl3: file(relativePath: { eq: "hegel-hoelderlin/reichl-3-tom.png" }) {
        ...largeImage
      }
      reichl4: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-4-sven.png" }
      ) {
        ...largeImage
      }
      reichl5: file(
        relativePath: { eq: "hegel-hoelderlin/reichl-5-boris.png" }
      ) {
        ...largeImage
      }
    }
  `)

  const QuoteAlign = (props) => {
    const { children } = props

    return (
      <Box
        position="relative"
        right={[0, 6, 6, -6, -12]}
        mt={[0, -12, null, -24]}
        zIndex={1}
      >
        <Constrain align="right">{children}</Constrain>
      </Box>
    )
  }

  return (
    <Stack space={[12, 24]}>
      <Constrain>
        <Stack>
          <Heading as="h2" level={2}>
            Hegel lesen. Lektüreerfahrungen in Text und Bild
          </Heading>
          <Stack>
            <Paragraph>
              Hegel und Hölderlin loteten in ihren Werken die Fähigkeiten der
              Sprache aus und trieben sie in ihren Texten bis an ihre äußersten
              Grenzen. Hölderlins ungewöhnliche Satzkonstruktionen stehen Hegels
              zirkulierenden Gedankengängen darin in Nichts nach. Ihre
              Leserinnen und Leser stellen sie damit vor eine Herausforderung.
              Umso kreativer und produktiver gestaltet sich der Versuch, ihren
              Texten habhaft zu werden, umso intensiver die Leseerfahrung.
              Frustrations- und Glücksmomente wechseln sich ab.
            </Paragraph>
            <Paragraph>
              In einer Reihe von Interviews hat die Künstlerin Veronika Reichl
              Lektüreerfahrungen zu Hegel gesammelt und in Zeichnungen und
              Geschichten übersetzt.
            </Paragraph>
            <Stack space={3}>
              <Text sans>
                Veronika Reichl:{" "}
                <Text bold as="span" color="primary">
                  ›Tom liest Hegel und schweigt‹
                </Text>
              </Text>
              <AudioPlayer
                src={`sonderausstellungen/hegel-hoelderlin/reichl-tom`}
              />
            </Stack>
            <Stack space={3}>
              <Text sans>
                Veronika Reichl:{" "}
                <Text bold as="span" color="primary">
                  ›Endlich liest Kati Alexandre Kojève‹
                </Text>
              </Text>
              <AudioPlayer
                src={`sonderausstellungen/hegel-hoelderlin/reichl-kati`}
              />
            </Stack>
          </Stack>
        </Stack>
      </Constrain>

      <Stack space={[12, 24]}>
        <Stack space={0}>
          <Image
            alt="Illustration zweier symmetrisch gespiegelter, geometrischer Formen mit roter und weißer Kontur auf einer grauen Fläche"
            image={data.reichl1.childImageSharp.gatsbyImageData}
          />
          <QuoteAlign>
            <QuoteBox author="Veronika Reichl: ›Hegel verblüfft Judith Butler, Judith Butler verblüfft Dana‹">
              Hegels Sätze treffen etwas in Dana. Etwas in ihrem Bewusstsein
              antwortet in sehr ähnlicher Art auf Hegels Worte, wie ihre
              Wirbelsäule antwortet, wenn ihre Yoga-Lehrerin sagt:{" "}
              <Text as="em">Unsere Wirbelsäulen schmelzen in den Boden</Text>.
              Denn Danas Wirbelsäule tut das – mit großer Begeisterung –, noch
              bevor Dana sich den Satz ganz bewusst gemacht hat.
            </QuoteBox>
          </QuoteAlign>
        </Stack>

        <Stack space={0}>
          <Image
            alt="Ein Meer von roten und vereinzelt auch weißen gezackten Formen in der unteren Bildhälfte auf grauem Hintergrund"
            image={data.reichl2.childImageSharp.gatsbyImageData}
          />
          <Box
            position="relative"
            right={[0, 6, 6, -6, -12]}
            mt={[0, -12]}
            zIndex={1}
          >
            <Constrain align="right">
              <QuoteBox author="Veronika Reichl: ›Hegel verblüfft Judith Butler, Judith Butler verblüfft Dana‹">
                Wenn jedes Wort und jeder Satz von Hegel alle denkbaren
                Bedeutungen mitmeint, entstehen ungeheure Vielheiten von
                Bedeutungsbläschen nebeneinander, die sich teils bestätigen,
                teils widersprechen und so zu einem guten Teil gleich wieder
                aufgrund ihrer Undeutbarkeit zerfallen, doch ohne ganz zu
                verschwinden. Und jeder neue Satz bringt neue Bläschen, die die
                alten zum Teil neu zu deuten erlauben und so in noch mehr
                Bläschen teilen: Es entsteht ein ungeheurer Schaum von
                Bedeutung.
              </QuoteBox>
            </Constrain>
          </Box>
        </Stack>

        <Stack space={0}>
          <Image
            alt="In der Mitte des Bildes befindet sich eine geometrische Fläche mit weißer Kontur, hellgrauer Füllung und einer weiteren kleinen, roten Form links daneben. Die Kanten der Form werden zum Bildrand durch weiße Linien verlängert. Der Hintergrund ist grau."
            image={data.reichl3.childImageSharp.gatsbyImageData}
          />
          <QuoteAlign>
            <QuoteBox author="Veronika Reichl: ›Tom liest Hegel und schweigt‹">
              Manchmal hat Tom die Empfindung, Hegel antworte prophylaktisch auf
              mögliche Fragen und Anmerkungen seiner Leser. Doch damit antwortet
              er nicht auf Tom und vor allem hat Tom trotzdem nichts gesagt.
            </QuoteBox>
          </QuoteAlign>
        </Stack>

        <Stack space={0}>
          <Image
            alt="Auf das Bild verteilt finden sich 7 zu Knäueln aufgerollten Figuren aus weißen und roten Linien"
            image={data.reichl4.childImageSharp.gatsbyImageData}
          />
          <QuoteAlign>
            <QuoteBox author="Veronika Reichl: ›Sven denkt in Denkfiguren‹">
              Hegel, dieser Drache, kann offensichtlich gar nicht anders: Wann
              immer er etwas denkt, faltet sich diese Figur auf neue Weise auf
              und im Auffalten auch schon wieder zu.
            </QuoteBox>
          </QuoteAlign>
        </Stack>

        <Stack space={0}>
          <Image
            alt="Zwei Fächer rotierender, halbtransparenter Pfeilfiguren in weiß und rot vor grauem Hintergrund"
            image={data.reichl5.childImageSharp.gatsbyImageData}
          />
          <QuoteAlign>
            <QuoteBox author="Veronika Reichl: ›Boris liest etwas, das nicht aufgeht, und schaut ›House of Cards‹‹">
              Der Dozent liest Hegel, als ob alles ohne Rest aufginge. Hegel
              selbst behauptet ja immer wieder, dass mit ihm eine riesige
              Bewegung ende. Etwas vollende sich und bilde nun eine gewaltige,
              fertige Ordnung. Der Staat Preußen und so weiter. Dann stehe alles
              still. Das kann Boris nicht ernst nehmen.
            </QuoteBox>
          </QuoteAlign>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Lektuere
