import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
/** @jsx jsx */
import { jsx } from "theme-ui"

import Box from "../../components/box"
import Container from "../../components/container"

const TimelineItem = (props) => {
  const { align, children } = props
  return (
    <Container maxWidth={align === "center" ? "lg" : "3xl"}>
      <Box display={["block", "block", "flex"]} alignX="center" px={[6, 12, 0]}>
        <Box position="relative" zIndex="10" bg="background">
          {children}
        </Box>
      </Box>
    </Container>
  )
}

TimelineItem.propTypes = {
  align: PropTypes.string,
}

TimelineItem.defaultProps = {
  align: "left",
}

export default TimelineItem
